<template>
  <div
    class="tw-bg-main d-flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    <p class="p-3 alert-danger d-inline-block fw-bolder">
      (shortcut)請稍後~正在為您轉址中...
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shortCut: '',
      serverToken: null,
    }
  },
  created() {
    this.initialization()
    if (!this.serverToken) {
      alert('您尚未登入，請先登入!')
      const identity = window.localStorage.getItem('identity') ? window.localStorage.getItem('identity') : 'customer'
      console.log(identity)
      if (identity === 'seller') return this.$router.push('/sellerLogin')
      else return this.$router.push('/customerLogin')
    }
    this.getStoreWithShortCut()
  },
  methods: {
    initialization() {
      this.shortCut = this.$route.params.shortCut
      this.serverToken = this.$methods.getCookie('serverToken')
    },
    getStoreWithShortCut() {
      const vm = this
      this.$methods.switchLoading('show')
      const getStoreWithShortCutApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const token = this.serverToken
      const header = {
        authorization: token,
      }
      const data = [
        {
          query: {"columns":[{"columnName":"id","siteObjectType":5}],"group":{"groups":[{"column":{"columnName":"shortCut","siteObjectType":5},"operator":{"operator":"=","type":1,"value":this.shortCut}}],"operator":1},"siteObjectType":5,"sqlCommandType":1},
          methods: '{}'
        },
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreWithShortCutApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.$router.push(`/customer/store/${storeInfo.id}/shop`)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
  },
}
</script>